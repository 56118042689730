import { dayjs } from "~/libs/dayjs"
import { useSetTestAnswersMutation } from "../mutations/useSetTestAnswerMutation"
import { useGetTestByIdQuery } from "../queries/getTestByIdQuery"
import { useWindowSize } from '@vueuse/core'

const currentQuestionNumber = ref(0)
const menuOpenModel = ref(false);
const isInReviewMode = ref(false)

export const useCurrentTest = () => {
  const route = useRoute()
  const { data, isLoading } = useGetTestByIdQuery(route.params.testId as string)
  const { mutate: setTestAnswers } = useSetTestAnswersMutation()

  watch(isLoading, (value) => {
    if (!value) {
      initPosition();
    }
  })

  const { width } = useWindowSize()
  const menuOpen = computed(() => {
    return width.value >= 960 || menuOpenModel.value;
  });

  const finishTime = computed(() => {
    // return dayjs().subtract(3, 'hours').toDate()

    return dayjs(data.value?.maxFinishTime).toDate()
  })

  const { finishTimeCountdown } = useCountdown(finishTime)

  const moveToTop = () => {
    nextTick(() => {
      window.scrollTo({ top: 0, behavior: 'instant' })
    })
  }

  const testId = computed(() => {
    return route.params.testId as string
  })

  const previousQuestion = () => {
    if (currentQuestionNumber.value > 0) {
      currentQuestionNumber.value--
      moveToTop()
    }
  }

  const nextQuestion = () => {
    if (currentQuestionNumber.value < data.value!.questions.length - 1) {
      currentQuestionNumber.value++
      moveToTop()
      menuOpenModel.value = false
    }
  }

  const goToQuestion = (question: number) => {
    if (question >= 0 && question < data.value!.questions.length) {
      currentQuestionNumber.value = question
      moveToTop()
      menuOpenModel.value = false
    }
  }

  const question = computed(() => {
    const question = data.value?.questions[currentQuestionNumber.value]

    return {
      ...question!,
      number: currentQuestionNumber.value + 1,
      selectedAnswer: data.value!.answers[currentQuestionNumber.value]
    }
  })

  const answers = computed(() => {
    if (!data.value?.answers) {
      return {}
    }

    return data.value!.answers
  })

  const selectAnswer = (answer: AnswerLetters | null) => {
    const answers = {
      ...data.value!.answers,
      [currentQuestionNumber.value]: answer
    }

    setTestAnswers({
      testId: testId.value,
      answers
    })
  }

  const initPosition = () => {
    const keys = Object.keys(answers.value)

    for (const key of keys) {
      const answer = answers.value[key as any]

      if (answer === null) {
        goToQuestion(Number(key))
        break
      }
    }
  }

  const answeredQuestions = computed(() => {
    return Object.keys(answers.value).filter(key => answers.value[key as any] !== null).length
  })

  const totalQuestions = computed(() => {
    return data.value?.questions.length ?? 0
  })

  const isOnLastQuestion = computed(() => {
    return currentQuestionNumber.value === totalQuestions.value - 1
  })

  const hasAnsweredAllQuestions = computed(() => {
    return answeredQuestions.value === totalQuestions.value
  })

  watch(currentQuestionNumber, (value) => {
    console.log(question.value.answer)
  })

  return {
    hasAnsweredAllQuestions,
    testId,
    isOnLastQuestion,
    isLoading,
    data,
    answeredQuestions,
    totalQuestions,
    currentQuestionNumber,
    initPosition,
    nextQuestion,
    previousQuestion,
    goToQuestion,
    question,
    answers,
    selectAnswer,
    finishTimeCountdown,
    menuOpen,
    isInReviewMode,
    switchMenu: () => {
      menuOpenModel.value = !menuOpenModel.value
      console.log(menuOpenModel.value)
    }
  }
}
