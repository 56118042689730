import { useMutation, useQueryClient } from "@tanstack/vue-query";

export const useSetTestAnswersMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: {
      testId: string,
      answers: {
        [key: string]: AnswerLetters | null
      }
    }) => {
      queryClient.setQueryData(["test", params.testId], (oldData: any) => {
        if (!oldData) return oldData

        return {
          ...oldData,
          answers: params.answers
        }
      })

      return useApi().test[":testId"].$patch({
        param: {
          testId: params.testId,
        },
        json: {
          answers: params.answers,
        },
      });
    },
  });
};
